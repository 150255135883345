import { defineAbility } from '@casl/ability'
import { createContext } from 'react'

import { ACTION } from 'auth/models/Permission'

import UserContext from './UserContext'

export function defineAbilityFor(user: UserContext['user']) {
  return defineAbility((can, cannot) => {
    can('use', 'App')

    if (user?.auth.permissions) {
      user.auth.permissions.map(([action, subject]) => can(action, subject))
    }

    // TEMPORARY: Users should not see advertise section
    cannot(ACTION.READ, 'frontend/advertise')
  })
}

const defaultAbility = defineAbilityFor(null)

export const AbilityContext = createContext(defaultAbility)

export default AbilityContext
