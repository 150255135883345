import { useAbility } from '@casl/react'
import { useMemo } from 'react'

import AbilityContext from 'auth/context/AbilityContext'
import { ACTION } from 'auth/models/Permission'

import { Permissions } from './types'

export type AbilityRecord = Record<Permissions, boolean>

export const useNavigationAbility = () => {
  const ability = useAbility(AbilityContext)

  return useMemo<AbilityRecord>(() => {
    return {
      'account/settings': ability.can(ACTION.READ, 'frontend/account/settings'),
      advertise: ability.can(ACTION.READ, 'frontend/advertise'),
      buy:
        ability.can(ACTION.READ, 'frontend/buy/special-deals') ||
        ability.can(ACTION.READ, 'frontend/buy/categories') ||
        ability.can(ACTION.READ, 'frontend/buy/brands') ||
        ability.can(ACTION.READ, 'frontend/buy/orders'),
      'buy/brands': ability.can(ACTION.READ, 'frontend/buy/brands'),
      'buy/buy-again': ability.can(ACTION.READ, 'frontend/buy/buy-again'),
      'buy/categories': ability.can(ACTION.READ, 'frontend/buy/categories'),
      'buy/orders': ability.can(ACTION.READ, 'frontend/buy/orders'),
      'buy/resupply': ability.can(ACTION.READ, 'frontend/buy/resupply'),
      'buy/special-deals': ability.can(ACTION.READ, 'frontend/buy/special-deals'),
      sell:
        ability.can(ACTION.READ, 'frontend/sell/orders') ||
        ability.can(ACTION.READ, 'frontend/sell/customers') ||
        ability.can(ACTION.READ, 'frontend/sell/products') ||
        ability.can(ACTION.READ, 'frontend/sell/locations') ||
        ability.can(ACTION.READ, 'frontend/sell/integrations'),
      'sell/customers': ability.can(ACTION.READ, 'frontend/sell/customers'),
      'sell/integrations': ability.can(ACTION.READ, 'frontend/sell/integrations'),
      'sell/locations': ability.can(ACTION.READ, 'frontend/sell/locations'),
      'sell/orders': ability.can(ACTION.READ, 'frontend/sell/orders'),
      'sell/products': ability.can(ACTION.READ, 'frontend/sell/products'),
    }
  }, [ability])
}

export default useNavigationAbility
