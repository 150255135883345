import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useUser from 'auth/hooks/useUser'
import { ACTION } from 'auth/models/Permission'
import LoadingFallback from 'common/components/LoadingFallback'
import useNavigationAbility from 'common/components/Nav/NavGuard/useNavigationAbility'
import { useNavigationStoresQuery } from 'common/graphql'

/**
 * TODO: move the logic to the server side
 *
 * For now, the api doesn't allow to use jwt without bugs.
 * When the api provides a new auth system, then move all the logic to the server side for better performance.
 */
function MainRedirect() {
  const router = useRouter()
  const { storeSlug } = router.query as { storeSlug?: string } // storeSlug is taken from the login
  const { data, loading } = useNavigationStoresQuery({
    variables: { first: 1, filter: { slugs: storeSlug ? [storeSlug] : undefined } },
    fetchPolicy: 'network-only',
  })
  const abilities = useNavigationAbility()
  const { user } = useUser()

  useEffect(() => {
    // wait for user authentication
    if (!user?.auth.authenticated) return

    // get store id
    const storeId = data?.stores?.edges?.[0]?.node.id

    // storeSlug is not set and user is a seller
    if (abilities['sell'] && !storeSlug) {
      router.push('/erp')
      return
    }

    // user has frame contract
    if (storeId && !loading) {
      router.push(`/store/${storeId}`)
      return
    }

    if (!storeId && !loading && abilities['sell']) {
      router.push('/erp')
    }

    // user has 0 frame contracts and isn't seller (admin)
    if (!loading && !storeId && !abilities['sell']) {
      router.push(`/settings/details/my`)
      return
    }
  }, [abilities, data?.stores?.edges, loading, router, storeSlug, user])

  return <LoadingFallback />
}

MainRedirect.authentication = {
  permissions: [[ACTION.CREATE, 'frontend/buy/orders']],
  required: true,
}

export default MainRedirect
